import {
  CloudDownloadOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Box, Button, Grid, Stack, Tab, Tabs } from "@mui/material";
import { Typography } from "@mui/material";
import { useSchool } from "api/school";
import NewTeacherPopupDialog from "components/dialogs/NewTeacherPopupDialog";
import SpecimenInfoTable from "components/specimen-info-table/SpecimenInfoTable";
import TeacherInfoTable from "components/teacher-info-table/TeacherInfoTable";
import moment from "moment";
import React, { useState } from "react";
import { useParams } from "react-router";
import { useUserInfo } from "store/selectors/auth";
import { Link, useSearchParams } from "react-router-dom";

const SchoolInfo = () => {
  const [newTeacherPopup, setNewTeacherPopup] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();

  const tab = searchParams.get("tab") || "teachers";

  const setTab = (tab) =>
    setSearchParams((params) => {
      params.set("tab", tab);
      return params;
    });

  const user = useUserInfo();

  const { data, isInitialLoading, isError } = useSchool(params.id);

  if (isInitialLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Error Occurred!</p>;
  }

  const teachers = data.teachers;

  const specimens = data.specimens;

  const handleDownload = () => {
    // Download as CSV

    let rows = [];

    const schoolRows = [
      ["Name", data.name],
      ["Province", data.province, "District", data.district],
      ["Area", data.addrress || ""],
      ["Phone Number", data.phoneNumber, "Email", data.email],
      [
        "Principal Name",
        data.principalName,
        "Phone Number",
        data.principalPhoneNumber,
      ],
      [
        "Contact Person",
        data.otherContactPersonName,
        "Phone Number",
        data.otherContactPersonPhoneNumber,
      ],
    ];

    rows.push(["School"]);

    rows = [...rows, ...schoolRows];

    rows.push(["Teachers"]);

    rows.push([
      "Subject",
      "Level",
      "Name",
      "Phone Number",
      "Area",
      "Province",
      "District",
      "Email",
      "Publication",
    ]);

    teachers.forEach((teacher) => {
      rows.push(
        [
          teacher.subject || " ",
          teacher.level || " ",
          teacher.name || " ",
          teacher.phoneNumber || " ",
          teacher.address || " ",
          teacher.province || " ",
          teacher.district || " ",
          teacher.email || " ",
          teacher.publication || " ",
        ].map((val) => `"${val}"`)
      );
    });

    rows.push(["Specimens"]);

    rows.push(["Book", "Level", "Date", "Has Received", "Remarks", "Teacher"]);

    specimens.forEach((specimen) => {
      rows.push(
        [
          specimen.book.name || " ",
          specimen.level || " ",
          specimen.specimenGivenDate
            ? moment(specimen.specimenGivenDate).format("YYYY-MM-DD")
            : " ",
          specimen.hasReceived ? "Yes" : "No",
          specimen.note || " ",
          specimen.teacher.name || " ",
        ].map((val) => `"${val}"`)
      );
    });

    const csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);

    const link = document.createElement("a");

    link.setAttribute("href", encodedUri);

    link.setAttribute("download", `Report ${data.name}.csv`);

    document.body.appendChild(link); // Required for FF

    link.click(); //
  };

  return (
    <>
      {["admin", "data-admin"].includes(user.role) && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 1 }}
          gap={2}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<CloudDownloadOutlined />}
            onClick={handleDownload}
          >
            Download
          </Button>

          <Link to={`/schools/${data.id}`}>
            <Button
              variant="contained"
              color="warning"
              startIcon={<EditOutlined />}
            >
              Edit
            </Button>
          </Link>
        </Stack>
      )}
      <Typography variant="h3" color="primary" gutterBottom>
        {data.name}
      </Typography>

      <Grid container sx={{ mb: 3 }}>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Province : {data.province}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            District : {data.district}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Area : {data.address}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Phone Number : {data.phoneNumber}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Email : {data.email}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Principal Name : {data.principalName}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Phone Number : {data.principalPhoneNumber}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Contact Person : {data.otherContactPersonName}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Phone Number : {data.otherContactPersonPhoneNumber}
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tab}
          onChange={(_, val) => setTab(val)}
          aria-label="basic tabs example"
        >
          <Tab label="Teachers" value={"teachers"} />
          <Tab label="Specimens" value={"specimens"} />
        </Tabs>
      </Box>

      {tab === "specimens" && (
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" color="primary" gutterBottom>
            Specimens
          </Typography>

          <SpecimenInfoTable data={specimens} />
        </Box>
      )}

      {tab === "teachers" && (
        <Box sx={{ my: 3 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 1,
            }}
          >
            <Typography variant="h4" color="primary" gutterBottom>
              Teachers
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlusOutlined />}
              onClick={() => setNewTeacherPopup(true)}
            >
              Add Teacher
            </Button>
          </Box>
          <TeacherInfoTable data={teachers} />
        </Box>
      )}

      <NewTeacherPopupDialog
        open={newTeacherPopup}
        setOpen={setNewTeacherPopup}
        initialData={{
          province: data.province,
          district: data.district,
          school: data.id,
        }}
        school={data}
      />
    </>
  );
};

export default SchoolInfo;
