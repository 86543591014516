import React, { useState } from "react";
import {
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Autocomplete } from "@mui/lab";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { DatePicker } from "@mui/x-date-pickers";
import { debounce } from "lodash";

const StyledAutocomplete = styled(Autocomplete)`
  .MuiOutlinedInput-root {
    padding: 0;

    input {
      padding: 10.5px 14px 10.5px 12px !important;
    }
  }
`;

const CustomInput = ({ field, form, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);

  const { name, type, options, label } = field;
  const { touched, errors, setFieldValue } = form;

  function handleInputChange(value) {
    // Fetch data from API
    field
      .fetchFn?.({ value })
      .then((data) => {
        // Update the options with the data received from the API
        setAutocompleteOptions(data);
      })
      .catch((error) => {
        console.error("Error fetching options:", error);
      });
  }

  const debouncedHandleInputChange = debounce(handleInputChange, 300);

  const handleInputDebounced = (value) => {
    debouncedHandleInputChange(value);
  };
  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
  };
  const handleBooleanChange = (e) => {
    setFieldValue(name, e.target.checked);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const renderInput = () => {
    if (field.name === "district") {
      const thisOptions = field.options[form.values.province] || [];

      return (
        <StyledAutocomplete
          fullWidth
          options={thisOptions}
          getOptionLabel={(option) => option?.label}
          value={
            thisOptions?.find((option) => option.value === form.values[name]) ||
            null
          }
          onChange={(event, newValue) => {
            setFieldValue(name, newValue ? newValue.value : "");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={touched[name] && Boolean(errors[name])}
              helperText={touched[name] && errors[name]}
            />
          )}
          {...props}
        />
      );
    }
    switch (type) {
      case "text":
      case "email":
        return (
          <TextField
            fullWidth
            autoComplete="off"
            name={name}
            label={label}
            value={form.values[name]}
            onChange={handleChange}
            error={touched[name] && Boolean(errors[name])}
            helperText={touched[name] && errors[name]}
            {...props}
          />
        );
      case "password":
        return (
          <TextField
            fullWidth
            name={name}
            label={label}
            type={showPassword ? "text" : "password"}
            value={form.values[name]}
            onChange={handleChange}
            error={touched[name] && Boolean(errors[name])}
            helperText={touched[name] && errors[name]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleTogglePassword}>
                    {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...props}
          />
        );
      case "radio":
        return (
          <FormControl component="fieldset">
            <RadioGroup
              row
              name={name}
              value={form.values[name]}
              onChange={handleChange}
            >
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case "checkbox":
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={form.values[name]}
                onChange={handleBooleanChange}
                name={name}
              />
            }
            label={label}
          />
        );
      case "select":
        return (
          <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
              name={name}
              value={form.values[name]}
              onChange={handleChange}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "select-search":
        return (
          <StyledAutocomplete
            fullWidth
            options={options}
            getOptionLabel={(option) => option.label}
            value={
              options.find((option) => option.value === form.values[name]) ||
              null
            }
            onChange={(event, newValue) => {
              setFieldValue(name, newValue ? newValue.value : "");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={touched[name] && Boolean(errors[name])}
                helperText={touched[name] && errors[name]}
              />
            )}
          />
        );
      case "datepicker":
        return (
          <DatePicker
            label="Specimen Given Date"
            sx={{ width: "100%" }}
            value={form.values[name]}
            onChange={(newValue) => setFieldValue(name, newValue)}
          />
        );

      case "autocomplete-api":
        return (
          <StyledAutocomplete
            fullWidth
            options={autocompleteOptions}
            getOptionLabel={(option) => option.label}
            value={
              autocompleteOptions.find(
                (option) => option.value === form.values[name]
              ) || null
            }
            onChange={(event, newValue) => {
              setFieldValue(name, newValue ? newValue.value : "");
            }}
            onInputChange={(e, value, reason) => {
              ["input", "clear"].includes(reason) &&
                value !== undefined &&
                handleInputDebounced(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={touched[name] && Boolean(errors[name])}
                helperText={touched[name] && errors[name]}
              />
            )}
          />
        );
      default:
        return null;
    }
  };

  return <>{renderInput()}</>;
};

export default CustomInput;
