// material-ui
import { Box } from "@mui/material";

// project import
import NavGroup from "./NavGroup";
import getMenuItems from "menu-items";
import { useUserInfo } from "store/selectors/auth";

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const userInfo = useUserInfo();
  const navGroups = getMenuItems(userInfo).items.map((item) => (
    <NavGroup key={item.id} item={item} />
  ));

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
