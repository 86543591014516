// components/CustomTable.js

import React, { useMemo, useReducer } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Select,
  MenuItem,
  Pagination,
  Typography,
} from "@mui/material";
import Loader from "components/Loader";
import styled from "@emotion/styled";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

const StyledHeaderCell = styled(TableCell)`
  font-weight: bold;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 1.1rem;
  text-transform: uppercase;
  cursor: pointer;

  span {
    margin-left: 4px;
  }
`;

const sortingReducer = (state, action) => {
  switch (action.type) {
    case "toggle":
      if (state.key === action.key) {
        return {
          key: state.key,
          direction: state.direction === "asc" ? "desc" : "asc",
        };
      } else {
        return { key: action.key, direction: "asc" };
      }
    default:
      return state;
  }
};

const CustomTable = ({
  data,
  headers,
  onDelete,
  onEdit,
  isLoading,
  onTableItemClick,
  disablePagination,

  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  totalPages,
  enableSort = true,
  search = "",

  showActions = true,
  shouldShowActionsForThisRow = () => true,
}) => {
  const [sorting, dispatchSorting] = useReducer(sortingReducer, {
    key: null,
    direction: null,
  });

  const handleSorting = (key) => {
    dispatchSorting({ type: "toggle", key });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeItemsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset the page to the first page when items per page changes
  };

  const sortedData = useMemo(() => {
    if (!sorting.key) {
      return data || [];
    }
    const sorted = [...(data || [])].sort((a, b) => {
      const aValue = a[sorting.key];
      const bValue = b[sorting.key];

      if (typeof aValue === "number" && typeof bValue === "number") {
        return sorting.direction === "asc" ? aValue - bValue : bValue - aValue;
      }

      if (typeof aValue === "string" && typeof bValue === "string") {
        const aString = aValue.toLowerCase();
        const bString = bValue.toLowerCase();

        if (aString < bString) {
          return sorting.direction === "asc" ? -1 : 1;
        }
        if (aString > bString) {
          return sorting.direction === "asc" ? 1 : -1;
        }
        return 0;
      }

      // Fallback to string comparison if the types are mixed or not handled
      const aString = aValue.toString().toLowerCase();
      const bString = bValue.toString().toLowerCase();

      if (aString < bString) {
        return sorting.direction === "asc" ? -1 : 1;
      }
      if (aString > bString) {
        return sorting.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    return sorted;
  }, [data, sorting]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <StyledHeaderCell
                  sx={{ width: header.width }}
                  key={index}
                  onClick={() => enableSort && handleSorting(header.key)}
                >
                  {header.label}
                  {sorting.key === header.key &&
                    (sorting.direction === "asc" ? (
                      <ArrowUpOutlined fontSize="small" />
                    ) : (
                      <ArrowDownOutlined fontSize="small" />
                    ))}
                </StyledHeaderCell>
              ))}
              {showActions && (
                <StyledHeaderCell sx={{ width: 120 }}>Actions</StyledHeaderCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                sx={{ cursor: onTableItemClick ? "pointer" : "unset" }}
                onClick={() => onTableItemClick?.(row)}
              >
                {headers.map((header, index) => {
                  if (header.type === "link") {
                    const value = header.getValue?.(row);
                    const link = header.getLink(row);
                    return (
                      <TableCell
                        key={index}
                        onClick={(e) => e.stopPropagation()}
                        sx={{ borderBottom: "1.5px solid #0d68b2" }}
                      >
                        {value && link && (
                          <Link
                            to={link}
                            style={{
                              textDecoration: "underline",
                              textDecorationColor: "InfoText",
                            }}
                          >
                            <Typography color="InfoText">
                              {value ?? row[header.key]}
                            </Typography>
                          </Link>
                        )}
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      sx={{ borderBottom: "1.5px solid #0d68b2" }}
                      key={index}
                    >
                      {header.getValue?.(row) ?? row[header.key]}
                    </TableCell>
                  );
                })}

                {showActions && (
                  <TableCell sx={{ borderBottom: "1.5px solid #0d68b2" }}>
                    {shouldShowActionsForThisRow(row) &&
                      (onEdit || onDelete) && (
                        <>
                          {onEdit && (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                onEdit(row);
                              }}
                            >
                              <EditOutlined />
                            </IconButton>
                          )}
                          {onDelete && (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                onDelete(row);
                              }}
                            >
                              <DeleteOutlined />
                            </IconButton>
                          )}
                        </>
                      )}
                  </TableCell>
                )}
              </TableRow>
            ))}
            {isLoading && <Loader />}
          </TableBody>
        </Table>
      </TableContainer>
      {!disablePagination && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Typography variant="subtitle1">
            Rows per page:
            <Select
              value={rowsPerPage}
              onChange={handleChangeItemsPerPage}
              sx={{ ml: 1 }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Typography>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>
      )}
    </>
  );
};

export default CustomTable;
