import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import TeacherForm from "components/forms/TeacherForm";
import React from "react";

const NewTeacherPopupDialog = ({ school, open, setOpen, initialData }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle variant="h4">New Teacher for {school.name}</DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <TeacherForm
          isNew={true}
          initialData={initialData}
          onSuccess={() => {
            setOpen(false);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default NewTeacherPopupDialog;
