import { Box, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import CreateSchool from "./CreateSchool";
import CreateTeacher from "./CreateTeacher";
import { useSearchParams } from "react-router-dom";

const CreateSchoolOrTeachers = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get("tab") || "schools";

  const setTab = (tab) =>
    setSearchParams((params) => {
      params.set("tab", tab);
      return params;
    });

  return (
    <Box>
      <Typography variant="h2" color="primary" gutterBottom>
        New School/Teacher
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tab}
          onChange={(_, val) => setTab(val)}
          aria-label="basic tabs example"
        >
          <Tab label="School" value={"schools"} />
          <Tab label="Teacher" value={"teachers"} />
        </Tabs>
      </Box>

      <Box sx={{ my: 3 }}>
        <CreateSchool tab={tab} />
        <CreateTeacher tab={tab} />
      </Box>
    </Box>
  );
};

export default CreateSchoolOrTeachers;
