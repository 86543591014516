import { useLocation, useNavigate, useRoutes } from "react-router-dom";

// project import
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import { useIsLoggedIn, useUserInfo } from "store/selectors/auth";
import { useEffect } from "react";
import UserManagementRoutes from "./UserManagementRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const isLoggedIn = useIsLoggedIn();
  const userInfo = useUserInfo();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    } else if (isLoggedIn && location.pathname.includes("login")) {
      navigate("/");
    }
  }, [isLoggedIn]);

  const routes = [MainRoutes, LoginRoutes];

  if (userInfo.role === "admin") {
    routes.push(UserManagementRoutes);
  }

  return useRoutes(routes);
}
