import { lazy } from "react";

import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import UserReport from "pages/users/report";

const Users = Loadable(lazy(() => import("pages/users/users")));

const UserManagementRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "users",
      element: <Users />,
    },
    {
      path: "users/:userId/report",
      element: <UserReport />,
    },
  ],
};

export default UserManagementRoutes;
