import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API_ENDPOINT } from "constants/index";
import { getCookie } from "utils/getCookie";

const getToken = () => getCookie("token");

export const downloadTeachers = ({
  search,
  province,
  district,
  address,
  subject,
  level,
}) => {
  return axios
    .get(`${API_ENDPOINT}/teachers/download`, {
      responseType: "blob",
      headers: { Authorization: `Bearer: ${getToken()}` },
      params: {
        search,
        province,
        district,
        address,
        subject,
        level,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Teachers.csv`); // Set the filename for download
      document.body.appendChild(link);
      link.click();
      return true;
    })
    .catch((error) => {
      console.error("Error downloading CSV:", error);
      throw error;
    });
};

export const useTeachers = ({ page, rowsPerPage, search }) =>
  useQuery(["Teachers", { page, rowsPerPage, search }], () =>
    axios
      .get(`${API_ENDPOINT}/teachers`, {
        headers: { Authorization: `Bearer: ${getToken()}` },
        params: {
          perPage: rowsPerPage,
          page,
          search,
        },
      })
      .then((res) => res.data)
  );

export const useTeachersSearch = ({
  search,
  province,
  district,
  address,
  subject,
  level,
  page,
  rowsPerPage,
}) => {
  const query = useQuery(
    [
      "Teachers",
      {
        search,
        province,
        district,
        subject,
        level,
        page,
        rowsPerPage,
        address,
      },
    ],
    () =>
      axios
        .get(`${API_ENDPOINT}/teachers`, {
          headers: { Authorization: `Bearer: ${getToken()}` },
          params: {
            perPage: rowsPerPage,
            page,
            search,
            province,
            district,
            address,
            subject,
            level,
          },
        })
        .then((res) => res.data),
    { enabled: false }
  );

  return [query.refetch, query];
};

export const useTeacher = (teacherId) =>
  useQuery(
    ["Teacher", { id: Number(teacherId) }],
    () =>
      axios
        .get(`${API_ENDPOINT}/teachers/${teacherId}`, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => res.data),
    {
      enabled: teacherId && teacherId !== "new",
      cacheTime: 0,
      retry: false,
    }
  );

export const useCreateTeacher = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables) =>
      axios
        .post(`${API_ENDPOINT}/teachers`, variables, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => res.data),
    onSuccess: (data, variables) => {
      queryClient.setQueriesData(
        ["School", { id: variables.school }],
        (oldData) => {
          const newSetOfData = {
            ...oldData,
            teachers: [
              { ...data, school: variables.school },
              ...oldData.teachers,
            ],
          };
          return newSetOfData;
        }
      );
    },
  });
};

export const useUpdateTeacher = () =>
  useMutation({
    mutationFn: (variables) =>
      axios
        .put(`${API_ENDPOINT}/teachers/${variables.id}`, variables, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => res.data),
  });

export const useDeleteTeacher = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables) =>
      axios
        .delete(`${API_ENDPOINT}/teachers/${variables.id}`, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => res.data),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["Teachers"]);
      queryClient.setQueriesData(
        ["School", { id: variables.school }],
        (oldData) => {
          return {
            ...oldData,
            teachers: oldData.teachers.filter(
              (teacher) => teacher.id !== variables.id
            ),
          };
        }
      );
    },
  });
};
