import CustomTable from "components/tables/CustomTable";
import React from "react";
import { useUserInfo } from "store/selectors/auth";

const headers = [
  {
    key: "name",
    label: "Name",
    type: "link",
    getLink: (row) => `/schools/info/${row.id}`,
    getValue: (row) => row.name,
  },
  { key: "province", label: "Province" },
  { key: "district", label: "District" },
  { key: "address", label: "Area" },
  { key: "phoneNumber", label: "Phone Number" },
];

const SchoolsTable = ({
  data,
  isLoading,
  handleEdit,
  searchQuery,
  handleTableItemClick,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  totalPages,
  disablePagination = false,
  showActions,
}) => {
  const userInfo = useUserInfo();

  return (
    <CustomTable
      data={data}
      headers={headers}
      isLoading={isLoading}
      onEdit={handleEdit}
      searchQuery={searchQuery}
      onTableItemClick={handleTableItemClick}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
      disablePagination={disablePagination}
      showActions={
        showActions ?? ["admin", "data-admin"].includes(userInfo?.role)
      }
    />
  );
};

export default SchoolsTable;
