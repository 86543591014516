import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import DynamicForm from "./DynamicForm";
import { useBooks } from "api/book";
import dayjs from "dayjs";
import {
  useCreateSpecimen,
  useDeleteSpecimen,
  useUpdateSpecimen,
} from "api/specimen";
import ConfirmDialog from "components/dialogs/ConfirmDialog";
import { useAlert } from "AlertContext";

const SpecimenForm = ({ initialData, disabled, isNew, onSuccess }) => {
  const {
    mutateAsync: mutateCreateSpecimen,
    isLoading: createSpecimentLoading,
  } = useCreateSpecimen();
  const {
    mutateAsync: mutateUpdateSpecimen,
    isLoading: updateSpecimenLoading,
  } = useUpdateSpecimen();
  const {
    mutateAsync: mutateDeleteSpecimen,
    isLoading: deleteSpecimenLoading,
  } = useDeleteSpecimen();

  const { data: books } = useBooks();

  const fields = [
    {
      name: "book",
      type: "select-search",
      label: "Book",
      options: books?.map(({ name, id }) => ({ value: id, label: name })) ?? [],
      itemProps: { xs: 12, sm: 6 },
    },
    {
      name: "level",
      type: "text",
      label: "Level",
      itemProps: { xs: 12, sm: 6 },
    },
    {
      name: "specimenGivenDate",
      type: "datepicker",
      label: "Date",
      itemProps: { xs: 12, sm: 6 },
    },
    {
      name: "hasReceived",
      type: "checkbox",
      label: "Has Received?",
      itemProps: { xs: 12, sm: 6 },
    },
    {
      name: "note",
      type: "text",
      label: "Remarks",
      itemProps: { xs: 12, sm: 12 },
    },
  ];

  const showAlert = useAlert();

  const form = useFormik({
    initialValues: {
      note: initialData?.note ?? "",
      specimenGivenDate: initialData?.specimenGivenDate
        ? dayjs(initialData?.specimenGivenDate)
        : null,
      book: initialData?.book ?? "",
      level: initialData?.level ?? "",
      hasReceived: initialData?.hasReceived ?? false,
    },
    validationSchema: yup.object().shape({
      note: yup.string().required("Note is required"),
      book: yup.number().required("Book is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        if (isNew) {
          await mutateCreateSpecimen({
            ...values,
            teacher: initialData.teacher,
            school: initialData.school,
          });

          showAlert("success", "Specimen added Successfully");
        } else {
          await mutateUpdateSpecimen({
            id: initialData.id,
            ...values,
          });
          showAlert("success", "Specimen updated Successfully");
        }
        onSuccess?.();
      } catch (err) {
        const message = err.response.data.message;
        showAlert("error", message);
      }
    },
  });

  const handleDelete = async () => {
    try {
      await mutateDeleteSpecimen({
        id: initialData.id,
        school: initialData.school,
        teacher: initialData.teacher,
      });

      showAlert("success", "Specimen deleted Successfully");

      onSuccess?.();
    } catch (err) {
      const message = err.response.data.message;
      showAlert("error", message);
    }
  };

  return (
    <div>
      <DynamicForm
        style={{ display: "flex", flexWrap: "wrap" }}
        orientation="row"
        form={form}
        submitLabel={isNew ? "Create" : "Update Specimen"}
        fields={fields}
        disabled={disabled || createSpecimentLoading || updateSpecimenLoading}
        additionalButtons={
          !isNew && (
            <>
              <ConfirmDialog
                onConfirm={handleDelete}
                buttonProps={{
                  variant: "outlined",
                  color: "error",
                  text: "Delete",
                  disabled:
                    disabled ||
                    createSpecimentLoading ||
                    updateSpecimenLoading ||
                    deleteSpecimenLoading,
                }}
              />
            </>
          )
        }
      />
    </div>
  );
};

export default SpecimenForm;
