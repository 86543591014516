// types
import { createSlice } from "@reduxjs/toolkit";
import { getCookie } from "utils/getCookie";
import { parseJwt } from "utils/parseJwt";

const initialToken = getCookie("token");

// initial state
const initialState = {
  token: initialToken ?? "",
  decodedData: parseJwt(initialToken),
};

// ==============================|| SLICE - Auth ||============================== //

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.token = action.payload.token;
      state.decodedData = parseJwt(action.payload.token);
    },
    logout(state) {
      state.token = "";
      state.decodedData = "";
    },
  },
});

export default auth.reducer;

export const { login, logout } = auth.actions;
