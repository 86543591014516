import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useIsLoggedIn } from 'store/selectors/auth';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();

  // Redirect to dashboard page if not logged in
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [navigate, isLoggedIn]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default MinimalLayout;
