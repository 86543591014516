import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API_ENDPOINT } from "constants/index";
import { getCookie } from "utils/getCookie";

const getToken = () => getCookie("token");

export const useLogin = () =>
  useMutation((variables) =>
    axios.post(`${API_ENDPOINT}/users/login`, variables)
  );

export const useRefreshToken = () =>
  useMutation((variables) =>
    axios.post(`${API_ENDPOINT}/users/refresh-token`, variables, {
      headers: { Authorization: `Bearer: ${getToken()}` },
    })
  );

export const useUsers = () =>
  useQuery(["Users"], () =>
    axios
      .get(`${API_ENDPOINT}/users`, {
        headers: { Authorization: `Bearer: ${getToken()}` },
      })
      .then((res) => res.data)
  );

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables) =>
      axios
        .delete(`${API_ENDPOINT}/users/${variables.id}`, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Users"]);
    },
  });
};

export const useGetUserLogs = ({ userId, date }, enabled = false) => {
  const query = useQuery(
    ["Logs", { userId, date }],
    () => {
      return axios
        .get(`${API_ENDPOINT}/logs`, {
          headers: { Authorization: `Bearer: ${getToken()}` },
          params: {
            userId,
            date,
          },
        })
        .then((res) => res.data);
    },
    {
      enabled,
    }
  );
  if (enabled) {
    return query;
  }
  return [query.refetch, query];
};

export const useCreateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables) =>
      axios
        .post(`${API_ENDPOINT}/users`, variables, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Users"]);
    },
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables) =>
      axios
        .put(`${API_ENDPOINT}/users/${variables.id}`, variables, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Users"]);
    },
  });
};
