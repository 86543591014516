import { Box, Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { useBook } from "api/book";
import BookForm from "components/forms/BookForm";
import SpecimenInfoTable from "components/specimen-info-table/SpecimenInfoTable";
import CustomTable from "components/tables/CustomTable";
import moment from "moment";
import React from "react";
import { useParams } from "react-router";

const BookInfo = () => {
  const params = useParams();

  const { data, isInitialLoading } = useBook(params.id);

  const isNew = !params.id || params.id === "new";

  const handleDelete = () => {
    alert("DELETE");
  };

  if (isInitialLoading) {
    return <p>Loading...</p>;
  }

  const specimens = data.specimens.map((specimen) => ({
    ...specimen,
    school: specimen.teacher.school,
  }));

  return (
    <>
      <Typography variant="h3" color="primary" gutterBottom>
        {data.name}
      </Typography>

      <Grid container sx={{ mb: 3 }}>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Level : {data.level}
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h4" color="primary" gutterBottom>
        Specimens
      </Typography>
      <SpecimenInfoTable data={specimens} showBookName={false} />
    </>
  );
};

export default BookInfo;
