import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API_ENDPOINT } from "constants/index";
import { getCookie } from "utils/getCookie";

const getToken = () => getCookie("token");

export const useCreateSpecimen = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables) =>
      axios
        .post(`${API_ENDPOINT}/specimens/`, variables, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => res.data),
    onSuccess: (data, variables) => {
      // If done in school page
      queryClient.setQueriesData(
        ["School", { id: variables.school }],
        (oldData) => {
          const newSetOfData = {
            ...oldData,
            teachers: oldData.teachers.map((teacher) => {
              if (teacher.id === variables.teacher) {
                return {
                  ...teacher,
                  specimens: [data, ...teacher.specimens],
                };
              } else {
                return teacher;
              }
            }),
            specimens: [data, ...oldData.specimens],
          };
          return newSetOfData;
        }
      );

      // If done in teacher page
      queryClient.setQueriesData(
        ["Teacher", { id: variables.teacher }],

        (oldData) => {
          const newSetOfData = {
            ...oldData,
            specimens: [data, ...oldData.specimens],
          };
          return newSetOfData;
        }
      );
    },
  });
};

export const useUpdateSpecimen = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables) =>
      axios
        .put(`${API_ENDPOINT}/specimens/${variables.id}`, variables, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => {
          queryClient.setQueriesData(["PendingSpecimens"], (oldData) => {
            const newSetOfData = {
              ...oldData,
              specimens: oldData.specimens.map((sp) =>
                sp.id === variables.id
                  ? {
                      ...sp,
                      hasReceived: res.data.hasReceived,
                      level: res.data.level,
                      note: res.data.note,
                      specimenGivenDate: res.data.specimenGivenDate,
                      book: res.data.book,
                    }
                  : sp
              ),
            };
            return newSetOfData;
          });
          return res.data;
        }),
  });
};

export const useDeleteSpecimen = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables) =>
      axios
        .delete(`${API_ENDPOINT}/specimens/${variables.id}`, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => res.data),

    onSuccess: (data, variables) => {
      // If done in pending specimens
      queryClient.setQueriesData(["PendingSpecimens"], (oldData) => {
        const newSetOfData = {
          ...oldData,
          specimens: oldData.specimens.filter(({ id }) => id !== variables.id),
        };
        return newSetOfData;
      });

      // If done in school page
      queryClient.setQueriesData(
        ["School", { id: variables.school }],
        (oldData) => {
          const newSetOfData = {
            ...oldData,
            teachers: oldData.teachers.map((teacher) => {
              if (teacher.id === variables.teacher) {
                return {
                  ...teacher,
                  specimens: teacher.specimens.filter(
                    ({ id }) => id !== variables.id
                  ),
                };
              } else {
                return teacher;
              }
            }),
          };
          return newSetOfData;
        }
      );

      // If done in teacher page
      queryClient.setQueriesData(
        ["Teacher", { id: variables.teacher }],

        (oldData) => {
          const newSetOfData = {
            ...oldData,
            specimens: oldData.specimens.filter(
              ({ id }) => id !== variables.id
            ),
          };
          return newSetOfData;
        }
      );
    },
  });
};

export const downloadSpecimens = ({
  province,
  district,
  address,
  book,
  school,
}) => {
  return axios
    .get(`${API_ENDPOINT}/specimens/csv`, {
      responseType: "blob",
      params: {
        province,
        district,
        address,
        book,
        school,
      },
      headers: { Authorization: `Bearer: ${getToken()}` },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Specimens.csv`); // Set the filename for download
      document.body.appendChild(link);
      link.click();
      return true;
    })
    .catch((error) => {
      console.error("Error downloading CSV:", error);
      throw error;
    });
};

export const useSpecimensSearch = ({
  province,
  district,
  address,
  book,
  school,
  page,
  perPage,
}) => {
  const query = useQuery(
    ["SpecimensSearch"],
    () => {
      return axios
        .get(`${API_ENDPOINT}/specimens/search`, {
          params: {
            province,
            district,
            address,
            book,
            school,
            page,
            perPage,
          },
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => res.data);
    },
    {
      enabled: true,
    }
  );
  return [query.refetch, query];
};
