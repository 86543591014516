import { PlusCircleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useSchoolsSearch } from "api/school";
import { useTeachersSearch } from "api/teacher";
import { PROVINCE_WISE_DISTRICT_OPTIONS } from "constants";
import { PROVINCE_OPTIONS } from "constants";
import { debounce } from "lodash";
import SchoolsTable from "pages/schools/schools/SchoolsTable";
import TeachersTable from "pages/teachers/teachers/TeachersTable";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

const StyledAutocomplete = styled(Autocomplete)`
  .MuiOutlinedInput-root {
    padding: 0;

    input {
      padding: 10.5px 14px 10.5px 12px !important;
    }
  }
`;

const Search = () => {
  const [params, setParams] = useSearchParams();

  const province = params.get("province") || "";
  const district = params.get("district") || "";
  const page = Number(params.get("page")) || 1;
  const rowsPerPage = Number(params.get("rowsPerPage")) || 10;
  const search = params.get("search") || "";
  const address = params.get("address") || "";
  const tab = params.get("tab") || "schools";

  const [searchInput, setSearchInput] = useState(search);
  const [addressInput, setAddressInput] = useState(address);

  const setProvince = (val) =>
    setParams((params) => {
      params.set("province", val);
      return params;
    });
  const setDistrict = (val) =>
    setParams((params) => {
      params.set("district", val);
      return params;
    });

  const setPage = (val) =>
    setParams((params) => {
      params.set("page", val);
      return params;
    });

  const setRowsPerPage = (val) =>
    setParams((params) => {
      params.set("rowsPerPage", val);
      return params;
    });

  const setSearch = (val) =>
    setParams((params) => {
      params.set("search", val);
      return params;
    });

  const setAddress = (val) =>
    setParams((params) => {
      params.set("address", val);
      return params;
    });

  const setTab = (val) =>
    setParams((params) => {
      params.set("tab", val);
      return params;
    });

  useEffect(() => {
    const debouncedState = debounce(() => {
      if (searchInput === search) return;

      setSearch(searchInput);
      setPage(1);
    }, 300);

    debouncedState();

    return debouncedState.cancel;
  }, [searchInput]);

  useEffect(() => {
    const debouncedState = debounce(() => {
      if (addressInput === address) return;

      setAddress(addressInput);
      setPage(1);
    }, 300);

    debouncedState();

    return debouncedState.cancel;
  }, [addressInput]);

  const navigate = useNavigate();

  const [fetchSchools, { data: schools, isFetching: isSchoolsFetching }] =
    useSchoolsSearch({
      search,
      province,
      district,
      address,
      page,
      rowsPerPage,
    });

  const [fetchTeachers, { data: teachers, isFetching: isTeachersFetching }] =
    useTeachersSearch({
      search,
      province,
      district,
      address,
      page,
      rowsPerPage,
    });

  const loading = isTeachersFetching || isSchoolsFetching;

  const DISTRICT_OPTIONS = PROVINCE_WISE_DISTRICT_OPTIONS[province] || [];

  const handleSearch = () => {
    if (tab === "schools") {
      fetchSchools();
    } else {
      fetchTeachers();
    }
  };

  useEffect(() => {
    handleSearch();
  }, [page, rowsPerPage, province, district, search, tab, address]);

  const handleTeachersEdit = (row) => {
    navigate(`/teachers/${row.id}`);
  };

  const handleSchoolsEdit = (row) => {
    navigate(`/schools/${row.id}`);
  };

  return (
    <Box>
      <Typography variant="h2" color="primary" gutterBottom>
        Search
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tab}
          onChange={(_, val) => {
            setPage(1);
            setTab(val);
          }}
          aria-label="basic tabs example"
        >
          <Tab label="Schools" value={"schools"} disabled={loading} />
          <Tab label="Teachers" value={"teachers"} disabled={loading} />
        </Tabs>
      </Box>

      <Box sx={{ my: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={"Search"}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Grid>
          <Grid item xs={district ? 4 : province ? 6 : 12}>
            <StyledAutocomplete
              fullWidth
              options={PROVINCE_OPTIONS}
              getOptionLabel={(option) => option?.label}
              value={
                PROVINCE_OPTIONS?.find((option) => option.value === province) ||
                null
              }
              onChange={(event, newValue) => {
                const val = newValue ? newValue.value : "";
                if (val) {
                  setProvince(val);
                } else {
                  setProvince("");
                  setDistrict("");
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label={"Province"} />
              )}
            />
          </Grid>

          {province && (
            <Grid item xs={district ? 4 : 6}>
              <StyledAutocomplete
                fullWidth
                options={DISTRICT_OPTIONS}
                getOptionLabel={(option) => option?.label}
                value={
                  DISTRICT_OPTIONS?.find(
                    (option) => option.value === district
                  ) || null
                }
                onChange={(event, newValue) => {
                  setDistrict(newValue ? newValue.value : "");
                }}
                renderInput={(params) => (
                  <TextField {...params} label={"District"} />
                )}
              />
            </Grid>
          )}

          {district && (
            <Grid item xs={4}>
              <TextField
                fullWidth
                label={"Area"}
                value={addressInput}
                onChange={(e) => setAddressInput(e.target.value)}
              />
            </Grid>
          )}
        </Grid>
      </Box>

      <Box>
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={3}
          >
            <CircularProgress />
            <Typography variant="h6">Loading...</Typography>
          </Box>
        ) : (
          <>
            {tab === "schools" && (
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 1 }}
                >
                  <Typography variant="h4" color="#FF8C00">
                    Schools
                  </Typography>
                  <Button
                    startIcon={<PlusCircleOutlined />}
                    color="orange"
                    sx={{ color: "white" }}
                    variant="contained"
                    onClick={() => navigate("/create/#schools")}
                  >
                    Create School
                  </Button>
                </Box>
                {schools?.schools?.length ? (
                  <SchoolsTable
                    data={schools.schools}
                    handleEdit={handleSchoolsEdit}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    page={page}
                    setPage={setPage}
                    totalPages={teachers?.totalPages}
                    disablePagination={true}
                    showActions={false}
                  />
                ) : (
                  <Typography variant="h6" color="primary" gutterBottom>
                    No schools found
                  </Typography>
                )}
              </Box>
            )}
            {tab === "teachers" && (
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ mb: 1 }}
                >
                  <Typography variant="h4" color="#FF8C00">
                    Teachers
                  </Typography>
                  <Button
                    startIcon={<PlusCircleOutlined />}
                    color="orange"
                    sx={{ color: "white" }}
                    variant="contained"
                    onClick={() => navigate("/create/#teachers")}
                  >
                    Create Teacher
                  </Button>
                </Box>
                {teachers?.teachers?.length ? (
                  <TeachersTable
                    data={teachers.teachers}
                    handleEdit={handleTeachersEdit}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    page={page}
                    setPage={setPage}
                    totalPages={teachers?.totalPages}
                    disablePagination={true}
                    showActions={false}
                    hideColumns={["level", "publication"]}
                  />
                ) : (
                  <Typography variant="h6" color="primary" gutterBottom>
                    No teachers found
                  </Typography>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Search;
