import React, { createContext, useContext, useState } from "react";
import { Snackbar, Alert } from "@mui/material";

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(null);

  const showAlert = (severity, message) => {
    setAlert({ severity, message });

    // Clear the alert after 3 seconds
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };

  return (
    <AlertContext.Provider value={showAlert}>
      {children}
      {alert && (
        <Snackbar
          open={true}
          autoHideDuration={3000}
          onClose={() => setAlert(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Alert severity={alert.severity}>{alert.message}</Alert>
        </Snackbar>
      )}
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const showAlert = useContext(AlertContext);
  return showAlert;
};
