// DynamicForm.js

import React from "react";
import { Button, Box, Grid } from "@mui/material";
import CustomInput from "./CustomInput";

const DynamicForm = ({
  fields,
  style = {},
  submitLabel = "Submit",
  form,
  orientation = "column",
  disabled,
  additionalButtons,
  hideSubmitButton = false,
  ...props
}) => {
  const itemProps = {};

  if (orientation === "column") {
    itemProps.xs = 12;
  } else {
    itemProps.flex = "1 0 300px";
  }

  return (
    <Box sx={{ mt: 0.75, width: "100%" }}>
      <form onSubmit={form.handleSubmit} {...props} style={{ flex: 1 }}>
        <Grid container spacing={2} style={style}>
          {fields.map((field) => (
            <Grid item {...(field.itemProps ?? itemProps)} key={field.name}>
              <CustomInput field={field} form={form} />
            </Grid>
          ))}

          {!hideSubmitButton && (
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 3 }}>
                {additionalButtons}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={disabled}
                >
                  {submitLabel}
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </form>
    </Box>
  );
};

export default DynamicForm;
