// project import

import main from "./main";
import user from "./user";

const getMenuItems = (userInfo) => {
  if (userInfo?.role === "admin") {
    return { items: [main, user] };
  } else {
    let fixedMain = { ...main };
    fixedMain.children = fixedMain.children.filter(({ role }) => {
      return role ? role.includes(userInfo?.role) : true;
    });

    return {
      items: [fixedMain],
    };
  }
};

export default getMenuItems;
