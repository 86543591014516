import { Typography } from "@mui/material";
import React from "react";
import { useUserInfo } from "store/selectors/auth";

const RouteControl = ({ children, allowedRoles }) => {
  const user = useUserInfo();

  if (allowedRoles.includes(user.role)) return children;

  return (
    <div>
      <Typography variant="h1">Not allowed</Typography>
    </div>
  );
};

export default RouteControl;
