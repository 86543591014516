import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import DynamicForm from "./DynamicForm";
import { useNavigate } from "react-router";
import { useCreateBook, useDeleteBook, useUpdateBook } from "api/book";
import ConfirmDialog from "components/dialogs/ConfirmDialog";
import { useQueryClient } from "@tanstack/react-query";
import { useAlert } from "AlertContext";

const fields = [
  {
    name: "name",
    type: "text",
    label: "Name",
  },
  {
    name: "level",
    type: "text",
    label: "Level",
  },
];

const BookForm = ({ initialData, disabled, isNew }) => {
  const navigate = useNavigate();
  const { mutateAsync: mutateCreateSchool, isLoading: createBookLoading } =
    useCreateBook();
  const { mutateAsync: mutateUpdateSchool, isLoading: updateBookLoading } =
    useUpdateBook();
  const { mutateAsync: mutateDeleteSchool, isLoading: deleteBookLoading } =
    useDeleteBook();

  const queryClient = useQueryClient();

  const showAlert = useAlert();

  const form = useFormik({
    initialValues: {
      name: initialData?.name ?? "",
      level: initialData?.level ?? "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
      level: yup.string().required("Level is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        if (isNew) {
          await mutateCreateSchool(values);
          //
          navigate(`/books`);
          queryClient.invalidateQueries(["Books"]);
          showAlert("success", "Book Created Successfully");
        } else {
          await mutateUpdateSchool({
            id: initialData.id,
            ...values,
          });
          showAlert("success", "Book Updated Successfully");
        }
      } catch (err) {
        const message = err.response.data.message;
        showAlert("error", message);
      }
    },
  });

  const handleDelete = async () => {
    try {
      await mutateDeleteSchool({ id: initialData.id });
      showAlert("success", "Book Deleted Successfully");
      navigate(`/books`);
    } catch (err) {
      const message = err.response.data.message;
      showAlert("error", message);
    }
  };

  return (
    <div>
      <DynamicForm
        form={form}
        submitLabel={isNew ? "Create" : "Update Book"}
        fields={fields}
        disabled={disabled || createBookLoading || updateBookLoading}
        additionalButtons={
          !isNew && (
            <>
              <ConfirmDialog
                onConfirm={handleDelete}
                buttonProps={{
                  variant: "outlined",
                  color: "error",
                  text: "Delete",
                  disabled:
                    disabled ||
                    createBookLoading ||
                    updateBookLoading ||
                    initialData.specimens.length !== 0,
                }}
              />
            </>
          )
        }
      />
    </div>
  );
};

export default BookForm;
