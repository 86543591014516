import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import SpecimenForm from "components/forms/SpecimenForm";
import React from "react";

const NewSpecimenPopupDialog = ({ teacher, open, setOpen }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle variant="h4">New Specimen for {teacher.name}</DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <SpecimenForm
          isNew={true}
          initialData={{ teacher: teacher.id, school: teacher.school }}
          onSuccess={() => {
            setOpen(false);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default NewSpecimenPopupDialog;
