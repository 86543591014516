import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import TeacherInfo from "pages/teachers/info";
import SchoolInfo from "pages/schools/info";
import BookInfo from "pages/books/info";
import CreateSchoolOrTeachers from "pages/create";
import Search from "pages/search";
import RouteControl from "./RouteControl";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));

// render - utilities
const Typography = Loadable(
  lazy(() => import("pages/components-overview/Typography"))
);
const Color = Loadable(lazy(() => import("pages/components-overview/Color")));
const Shadow = Loadable(lazy(() => import("pages/components-overview/Shadow")));
const AntIcons = Loadable(
  lazy(() => import("pages/components-overview/AntIcons"))
);
const School = Loadable(lazy(() => import("pages/schools/school")));

const Books = Loadable(lazy(() => import("pages/books/books")));
const Book = Loadable(lazy(() => import("pages/books/book")));

const Teacher = Loadable(lazy(() => import("pages/teachers/teacher")));
const SpecimenManagement = Loadable(
  lazy(() => import("pages/specimen-management"))
);

// ==============================|| MAIN ROUTING ||============================== //

const docRoutes = [
  {
    path: "color",
    element: <Color />,
  },
  {
    path: "shadow",
    element: <Shadow />,
  },
  {
    path: "typography",
    element: <Typography />,
  },
  {
    path: "icons/ant",
    element: <AntIcons />,
  },
];

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/create",
      element: (
        <RouteControl allowedRoles={["admin", "data-admin", "user"]}>
          <CreateSchoolOrTeachers />
        </RouteControl>
      ),
    },
    {
      path: "/schools/:id",
      element: (
        <RouteControl allowedRoles={["admin", "data-admin"]}>
          <School />
        </RouteControl>
      ),
    },
    {
      path: "/schools/info/:id",
      element: (
        <RouteControl allowedRoles={["admin", "data-admin", "user"]}>
          <SchoolInfo />
        </RouteControl>
      ),
    },
    {
      path: "/teachers/:id",
      element: (
        <RouteControl allowedRoles={["admin", "data-admin"]}>
          <Teacher />
        </RouteControl>
      ),
    },
    {
      path: "/teachers/info/:id",
      element: (
        <RouteControl allowedRoles={["admin", "data-admin", "user"]}>
          <TeacherInfo />
        </RouteControl>
      ),
    },
    {
      path: "/books",
      element: (
        <RouteControl allowedRoles={["admin", "data-admin"]}>
          <Books />
        </RouteControl>
      ),
    },
    //
    {
      path: "/books/:id",
      element: (
        <RouteControl allowedRoles={["admin", "data-admin"]}>
          <Book />
        </RouteControl>
      ),
    },
    {
      path: "/books/info/:id",
      element: (
        <RouteControl allowedRoles={["admin", "data-admin"]}>
          <BookInfo />
        </RouteControl>
      ),
    },
    {
      path: "/report",
      element: (
        <RouteControl allowedRoles={["admin", "data-admin"]}>
          <DashboardDefault />
        </RouteControl>
      ),
    },
    {
      path: "/",
      element: (
        <RouteControl allowedRoles={["admin", "data-admin", "user"]}>
          <Search />
        </RouteControl>
      ),
    },
    {
      path: "/specimen-management",
      element: (
        <RouteControl allowedRoles={["admin", "data-admin", "user"]}>
          <SpecimenManagement />
        </RouteControl>
      ),
    },
    ...docRoutes,
  ],
};

export default MainRoutes;
