// assets
import {
  BankOutlined,
  ReadOutlined,
  SearchOutlined,
  UserOutlined,
  PlusCircleOutlined,
  BarsOutlined,
  HourglassOutlined,
} from "@ant-design/icons";

// icons
const icons = {
  BankOutlined,
  ReadOutlined,
  UserOutlined,
  SearchOutlined,
  PlusCircleOutlined,
  BarsOutlined,
  HourglassOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: "main-group",
  title: "Data Management",
  type: "group",
  children: [
    {
      id: "",
      title: "Search",
      type: "item",
      url: "/",
      icon: icons.SearchOutlined,
      breadcrumbs: false,
    },
    {
      id: "create",
      title: "Create School/Teachers",
      type: "item",
      url: "/create",
      icon: icons.PlusCircleOutlined,
      breadcrumbs: false,
    },
    {
      id: "report",
      title: "Report",
      type: "item",
      url: "/report",
      icon: icons.BarsOutlined,
      breadcrumbs: false,
      role: ["admin", "data-admin"],
    },
    // {
    //   id: "pending-specimens",
    //   title: "Pending Specimens",
    //   type: "item",
    //   url: "/pending-specimens",
    //   icon: icons.HourglassOutlined,
    //   breadcrumbs: false,
    //   role: ["admin", "data-admin"],
    // },
    {
      id: "speciment-management",
      title: "Specimen Management",
      type: "item",
      url: "/specimen-management",
      icon: icons.HourglassOutlined,
      breadcrumbs: false,
    },
    {
      id: "books",
      title: "Books",
      type: "item",
      url: "/books",
      icon: icons.ReadOutlined,
      breadcrumbs: false,
      role: ["admin", "data-admin"],
    },
  ],
};

export default dashboard;
