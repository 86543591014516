import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";

const ConfirmDialog = ({ buttonProps, onConfirm }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle variant="h2" color="primary">
          Confirm?
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>Are you sure?</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(false);
              onConfirm?.();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Button {...buttonProps} /> */}
      <Button
        variant={buttonProps.variant}
        color={buttonProps.color}
        onClick={() => setOpen(true)}
        disabled={buttonProps.disabled}
      >
        {buttonProps.text}
      </Button>
    </>
  );
};

export default ConfirmDialog;
