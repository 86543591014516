// project import
import Routes from "routes";
import ThemeCustomization from "themes";
import ScrollTop from "components/ScrollTop";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";

import { login, logout } from "store/reducers/auth";
import { useRefreshToken } from "api/user";
import { getCookie } from "utils/getCookie";
import { AlertProvider } from "AlertContext";
import axios from "axios";

import "./App.css";

const App = () => {
  const [_, setCookie] = useCookies(["token"]);
  const { mutateAsync } = useRefreshToken();
  const dispatch = useDispatch();

  useEffect(() => {
    // Create Axios interceptor
    const interceptor = axios.interceptors.response.use(
      (response) => {
        // If the response is successful, return it as is
        return response;
      },
      (error) => {
        // If the response status is 401 (Unauthorized), handle it here
        if (error.response && error.response.status === 401) {
          // Handle the 401 error here
          dispatch(logout());
          // You can redirect the user to a login page or display a custom error message
        }

        // Return the error to the calling function
        return Promise.reject(error);
      }
    );

    // Cleanup the interceptor when the component is unmounted
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [dispatch]);

  // Try to refresh token every 2 hour
  useEffect(() => {
    const refreshCookie = async () => {
      const token = getCookie("token");

      if (token) {
        const response = await mutateAsync();
        setCookie("token", response.data.token, {
          expires: new Date(Date.now() + 46400_000),
        });
        dispatch(login({ token: response.data.token }));
      }
    };

    const interval = setInterval(() => {
      refreshCookie();
    }, 20 * 60 * 1000);

    refreshCookie();

    return () => {
      clearInterval(interval);
    };
  }, [setCookie]);

  return (
    <AlertProvider>
      <ThemeCustomization>
        <ScrollTop>
          <Routes />
        </ScrollTop>
      </ThemeCustomization>
    </AlertProvider>
  );
};

export default App;
