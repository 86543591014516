import { Typography } from "@mui/material";
import SchoolForm from "components/forms/SchoolForm";
import React from "react";

const CreateSchool = ({ tab }) => {
  if (tab !== "schools") return null;
  return (
    <div>
      <Typography variant="h4" color="#ee7600" gutterBottom>
        Create School
      </Typography>
      <SchoolForm isNew={true} navigateToListView={false} />
    </div>
  );
};

export default CreateSchool;
