import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API_ENDPOINT } from "constants/index";
import { getCookie } from "utils/getCookie";

const getToken = () => getCookie("token");

export const useBooks = () =>
  useQuery(
    ["Books"],
    () =>
      axios
        .get(`${API_ENDPOINT}/books`, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => res.data),
    {}
  );

export const useBook = (bookId) =>
  useQuery(
    ["Book", { id: Number(bookId) || null }],
    () =>
      axios
        .get(`${API_ENDPOINT}/books/${bookId}`, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => res.data),
    {
      enabled: bookId && bookId !== "new",
      cacheTime: 0,
    }
  );

export const useCreateBook = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables) =>
      axios
        .post(`${API_ENDPOINT}/books/`, variables, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => res.data),
    onSuccess: () => queryClient.refetchQueries(["Books"]),
  });
};

export const useUpdateBook = () =>
  useMutation({
    mutationFn: (variables) =>
      axios
        .put(`${API_ENDPOINT}/books/${variables.id}`, variables, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => res.data),
  });

export const useDeleteBook = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables) =>
      axios
        .delete(`${API_ENDPOINT}/books/${variables.id}`, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries(["Books"]);
    },
  });
};
