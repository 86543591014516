import CustomTable from "components/tables/CustomTable";
import React from "react";

const headers = [
  { key: "subject", label: "Subject" },
  { key: "level", label: "Level" },
  {
    key: "name",
    label: "Name",
    type: "link",

    getValue: (row) => row.name,
    getLink: (row) => `/teachers/info/${row.id}`,
  },
  { key: "phoneNumber", label: "Phone Number" },
  { key: "address", label: "Area" },
];

const TeacherInfoTable = ({ data }) => {
  return (
    <CustomTable
      data={data}
      headers={headers}
      disablePagination
      showActions={false}
    />
  );
};

export default TeacherInfoTable;
