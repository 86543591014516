import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API_ENDPOINT } from "constants/index";
import { getCookie } from "utils/getCookie";

const getToken = () => getCookie("token");

export const downloadSchools = ({ search, province, district, address }) => {
  return axios
    .get(`${API_ENDPOINT}/schools/download`, {
      responseType: "blob",
      headers: { Authorization: `Bearer: ${getToken()}` },
      params: {
        search,
        province,
        district,
        address,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Schools.csv`); // Set the filename for download
      document.body.appendChild(link);
      link.click();
      return true;
    })
    .catch((error) => {
      console.error("Error downloading CSV:", error);
      throw error;
    });
};

export const useSchools = ({ page, rowsPerPage, search }) =>
  useQuery(["Schools", { page, rowsPerPage, search }], () =>
    axios
      .get(`${API_ENDPOINT}/schools`, {
        headers: { Authorization: `Bearer: ${getToken()}` },
        params: {
          perPage: rowsPerPage,
          page,
          search,
        },
      })
      .then((res) => res.data)
  );

export const useSchoolsSearch = (
  { search, province, district, address, page, rowsPerPage },
  enabled = false
) => {
  const query = useQuery(
    ["Schools", { search, province, district, page, rowsPerPage }],
    () => {
      return axios
        .get(`${API_ENDPOINT}/schools`, {
          headers: { Authorization: `Bearer: ${getToken()}` },
          params: {
            perPage: rowsPerPage,
            page: page,
            search,
            province,
            district,
            address,
          },
        })
        .then((res) => res.data);
    },
    {
      enabled,
    }
  );
  if (enabled) {
    return query;
  }
  return [query.refetch, query];
};

export const useSchool = (schoolId) =>
  useQuery(
    ["School", { id: Number(schoolId) }],
    () =>
      axios
        .get(`${API_ENDPOINT}/schools/${schoolId}`, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => {
          const data = res.data;
          data.teachers = data.teachers.map((teacher) => ({
            ...teacher,
            school: res.data.id,
          }));
          return data;
        }),
    {
      enabled: schoolId && schoolId !== "new",
      cacheTime: 0,
      retry: false,
    }
  );

export const useCreateSchool = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables) =>
      axios
        .post(`${API_ENDPOINT}/schools/`, variables, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => res.data),
    onSuccess: () => queryClient.invalidateQueries(["Schools"]),
  });
};

export const useUpdateSchool = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables) =>
      axios
        .put(`${API_ENDPOINT}/schools/${variables.id}`, variables, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => res.data),
    onSuccess: () => queryClient.invalidateQueries(["Schools"]),
  });
};

export const useDeleteSchool = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables) =>
      axios
        .delete(`${API_ENDPOINT}/schools/${variables.id}`, {
          headers: { Authorization: `Bearer: ${getToken()}` },
        })
        .then((res) => res.data),
    onSuccess: () => queryClient.invalidateQueries(["Schools"]),
  });
};
