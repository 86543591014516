import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import DynamicForm from "./DynamicForm";
import {
  useCreateTeacher,
  useDeleteTeacher,
  useUpdateTeacher,
} from "api/teacher";
import { Box, Button, Card, IconButton, Typography } from "@mui/material";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import NewSpecimenPopupDialog from "components/dialogs/NewSpecimenPopupDialog";
import SpecimenForm from "./SpecimenForm";
import ConfirmDialog from "components/dialogs/ConfirmDialog";
import { useQueryClient } from "@tanstack/react-query";
import { PROVINCE_OPTIONS } from "constants";
import { PROVINCE_WISE_DISTRICT_OPTIONS } from "constants";
import { useAlert } from "AlertContext";

const fields = [
  {
    name: "name",
    type: "text",
    label: "Name",
    itemProps: {
      xs: 12,
      sm: 6,
    },
  },

  {
    name: "phoneNumber",
    type: "text",
    label: "Phone Number",
    itemProps: {
      xs: 12,
      sm: 6,
    },
  },
  {
    name: "subject",
    type: "text",
    label: "Subject",
    itemProps: {
      xs: 12,
      sm: 4,
    },
  },

  {
    name: "level",
    type: "text",
    label: "Level",
    itemProps: {
      xs: 12,
      sm: 4,
    },
  },
  {
    name: "publication",
    type: "text",
    label: "Publication",
    itemProps: {
      xs: 12,
      sm: 4,
    },
  },
  {
    name: "province",
    type: "select-search",
    label: "Province",
    options: PROVINCE_OPTIONS,
    itemProps: { xs: 12, sm: 4 },
  },
  {
    name: "district",
    type: "text",
    label: "District",
    options: PROVINCE_WISE_DISTRICT_OPTIONS,
    itemProps: { xs: 12, sm: 4 },
  },
  {
    name: "address",
    type: "text",
    label: "Area",
    itemProps: {
      xs: 12,
      sm: 4,
    },
  },
  {
    name: "email",
    type: "text",
    label: "Email",
    itemProps: {
      xs: 12,
      sm: 12,
    },
  },
];

const TeacherForm = ({
  initialData,
  disabled,
  isNew,
  onSuccess,
  hideDropdown = false,
  onDeleteSuccess,
  newFields,
  showDeleteButton = true,
  showSpecimenSection = true,
}) => {
  const [showSpecimen, setShowSpecimen] = useState(false);
  const [newSpecimenPopup, setNewSpecimenPopup] = useState(false);

  const { mutateAsync: mutateCreateTeacher, isLoading: createTeacherLoading } =
    useCreateTeacher();
  const { mutateAsync: mutateUpdateTeacher, isLoading: updateTeacherLoading } =
    useUpdateTeacher();
  const { mutateAsync: mutateDeleteTeacher, isLoading: deleteTeacherLoading } =
    useDeleteTeacher();

  const queryClient = useQueryClient();

  const showAlert = useAlert();

  const form = useFormik({
    initialValues: {
      name: initialData?.name ?? "",
      phoneNumber: initialData?.phoneNumber ?? "",
      address: initialData?.address ?? "",
      email: initialData?.email ?? "",
      subject: initialData?.subject ?? "",
      level: initialData?.level ?? "",
      province: initialData?.province ?? "",
      district: initialData?.district ?? "",
      publication: initialData?.publication ?? "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
      phoneNumber: yup.string().required("Phone number is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        if (isNew) {
          await mutateCreateTeacher({
            school: initialData?.school,
            ...values,
          });

          showAlert("success", "Teacher Added Successfully");
          form.resetForm({
            values: {
              name: "",
              phoneNumber: "",
              address: "",
              email: "",
              subject: "",
              level: "",
              province: "",
              district: "",
              school: "",
            },
          });
          onSuccess?.();
          //
        } else {
          await mutateUpdateTeacher({
            id: initialData.id,
            ...values,
          });
          showAlert("success", "Teacher Updated Successfully");
        }
        queryClient.invalidateQueries(["Teachers"]);
        onSuccess?.();
      } catch (err) {
        const message = err.response.data.message;
        showAlert("error", message);
      }
    },
  });

  const handleDelete = async () => {
    try {
      await mutateDeleteTeacher({
        id: initialData.id,
        school: initialData.school,
      });
      onDeleteSuccess?.();

      showAlert("success", "Teacher Deleted Successfully");
    } catch (err) {
      const message = err.response.data.message;
      showAlert("error", message);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box display="flex" gap="20px" alignItems="center">
        <DynamicForm
          orientation="column"
          form={form}
          submitLabel={isNew ? "Create" : "Update"}
          fields={newFields || fields}
          disabled={disabled || createTeacherLoading || updateTeacherLoading}
          additionalButtons={
            !isNew &&
            showDeleteButton && (
              <>
                <ConfirmDialog
                  onConfirm={handleDelete}
                  buttonProps={{
                    variant: "outlined",
                    color: "error",
                    text: "Delete",
                    disabled:
                      disabled ||
                      createTeacherLoading ||
                      updateTeacherLoading ||
                      deleteTeacherLoading ||
                      initialData.specimens.length !== 0,
                  }}
                />
              </>
            )
          }
        />
        {!isNew && !hideDropdown && (
          <IconButton onClick={() => setShowSpecimen((prev) => !prev)}>
            {showSpecimen ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
          </IconButton>
        )}
      </Box>
      {showSpecimenSection && !isNew && (showSpecimen || hideDropdown) && (
        <>
          <Box sx={{ my: 3 }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5" color="primary">
                Specimens
              </Typography>

              <Button
                variant="contained"
                color="primary"
                startIcon={<PlusOutlined />}
                sx={{ width: 200 }}
                onClick={() => setNewSpecimenPopup(true)}
              >
                Add New Specimen
              </Button>
            </Box>

            <Box>
              {initialData.specimens.map((specimen) => (
                <Card key={specimen.id} sx={{ p: 3, my: 3 }}>
                  <SpecimenForm
                    initialData={{
                      ...specimen,
                      teacher: initialData.id,
                      book: specimen.book.id,
                      school: initialData.school,
                    }}
                  />
                </Card>
              ))}
            </Box>
          </Box>
          <NewSpecimenPopupDialog
            open={newSpecimenPopup}
            setOpen={setNewSpecimenPopup}
            teacher={initialData}
          />
        </>
      )}
    </Box>
  );
};

export default TeacherForm;
