import {
  CloudDownloadOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Box, Button, Grid, Stack } from "@mui/material";
import { Typography } from "@mui/material";
import { useTeacher } from "api/teacher";
import NewSpecimenPopupDialog from "components/dialogs/NewSpecimenPopupDialog";
import SpecimenInfoTable from "components/specimen-info-table/SpecimenInfoTable";
import moment from "moment";
import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useUserInfo } from "store/selectors/auth";

const TeacherInfo = () => {
  const params = useParams();
  const { data, isInitialLoading, isError } = useTeacher(params.id);
  const [newSpecimenPopup, setNewSpecimenPopup] = React.useState(false);

  const user = useUserInfo();

  if (isInitialLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Error Occurred!</p>;
  }

  const handleDownload = () => {
    let rows = [];

    rows.push(["Teacher"]);

    const teacherInfo = [
      ["Name", data.name],
      ["School", data.school.name],
      ["Subject", data.subject, "Level", data.level],
      ["Phone Number", data.phoneNumber, "Email", data.email],
      ["Province", data.province, "District", data.district],
      ["Area", data.addrress, "Publication", data.publication].map(
        (val) => `"${val}"`
      ),
    ];

    rows = [...rows, ...teacherInfo];

    rows.push(["Specimens"]);

    rows.push(["Book", "Level", "Date", "Has Received?", "Remarks"]);

    data.specimens.forEach((specimen) => {
      rows.push(
        [
          specimen.book.name || " ",
          specimen.level || " ",
          specimen.specimenGivenDate
            ? moment(specimen.specimenGivenDate).format("YYYY-MM-DD")
            : " ",
          specimen.hasReceived ? "Yes" : "No" || " ",
          specimen.note || " ",
        ].map((val) => `"${val}"`)
      );
    });

    const csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);

    const link = document.createElement("a");

    link.setAttribute("href", encodedUri);

    link.setAttribute("download", `Report ${data.name}.csv`);

    document.body.appendChild(link); // Required for FF

    link.click(); //
  };

  return (
    <>
      {["admin", "data-admin"].includes(user.role) && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 1 }}
          gap={2}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<CloudDownloadOutlined />}
            onClick={handleDownload}
          >
            Download
          </Button>

          <Link to={`/teachers/${data.id}`}>
            <Button
              variant="contained"
              color="warning"
              startIcon={<EditOutlined />}
            >
              Edit
            </Button>
          </Link>
        </Stack>
      )}
      <Typography variant="h3" color="primary" gutterBottom>
        <Link to={`/schools/info/${data.school.id}`}>{data.school.name}</Link>
      </Typography>
      <Typography variant="h3" color="primary" gutterBottom>
        {data.name}
      </Typography>

      <Grid container sx={{ mb: 3 }}>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Subject : {data.subject}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Level : {data.level}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Phone Number : {data.phoneNumber}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Email : {data.email}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Province : {data.province}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            District : {data.district}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Area : {data.address}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Publication : {data.publication}
          </Typography>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Typography variant="h4" color="primary" gutterBottom>
          Specimens
        </Typography>

        <Button
          variant="contained"
          color="primary"
          startIcon={<PlusOutlined />}
          onClick={() => setNewSpecimenPopup(true)}
        >
          Add Specimen
        </Button>
      </Box>
      <SpecimenInfoTable data={data.specimens} />

      <NewSpecimenPopupDialog
        open={newSpecimenPopup}
        setOpen={setNewSpecimenPopup}
        teacher={data}
      />
    </>
  );
};

export default TeacherInfo;
