import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { useGetUserLogs, useUsers } from "api/user";
import CustomTable from "components/tables/CustomTable";

function titleCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const headers = [
  {
    key: "createdAt",
    label: "Time",

    getValue: (row) => dayjs(row.createdAt).format("hh:mm A"),
  },

  {
    key: "action",
    label: "Action",
    getValue: (row) => `${titleCase(row.action)}d ${row.entity}`,
  },
  {
    key: "description",
    label: "Description",
    width: 500,
    getValue: (row) => {
      if (row.entity === "Specimen") {
        return (
          <span>
            {titleCase(row.action)}d {row.entity} <br />
            Book - {row.data?.book?.name} <br />
            School - {row.data?.school?.name}
          </span>
        );
      }
      if (row.action === "CREATE") {
        return (
          <span>
            {titleCase(row.action)}d {row.entity} - {row.data?.name}
          </span>
        );
      }
      if (row.action === "UPDATE") {
        return (
          <span>
            {titleCase(row.action)}d {row.entity} - {row.data?.name}
          </span>
        );
      }
      if (row.action === "DELETE") {
        return `Deleted ${row.entity}(${row.entityId}) - ${row.data?.name}`;
      }
    },
  },
  {
    key: "view",
    label: "",
    type: "link",
    getValue: (row) =>
      row.entity === "Specimen" ? "View" : row.action !== "DELETE" && "View",
    getLink: (row) => {
      switch (row.entity) {
        case "School":
          return `/schools/info/${row.entityId}`;
        case "Teacher":
          return `/teachers/${row.entityId}`;
        case "Book":
          return `/books/${row.entityId}`;
        case "Specimen":
          return `/schools/info/${row.data?.school?.id}?tab=specimens`;
        default:
          return null;
      }
    },
  },
];

const UserReport = () => {
  const [date, setDate] = useState(dayjs());
  const { data: users, isLoading: isUsersLoading } = useUsers();

  const { userId } = useParams();

  const [fetch, { data, isLoading: isLogsLoading }] = useGetUserLogs({
    userId,
    date,
  });

  useEffect(() => {
    fetch();
  }, [date, userId]);

  if (isUsersLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography variant="h1" color={"#FF8C00"}>
          Report of {users?.find((user) => user.id === Number(userId))?.name}
        </Typography>

        <DatePicker
          label="Date"
          value={date}
          onChange={(newValue) => setDate(newValue)}
        />
      </Stack>
      {isLogsLoading && <div>Loading...</div>}
      <CustomTable
        data={data?.logs || []}
        headers={headers}
        isLoading={isLogsLoading}
        disablePagination={true}
        showActions={false}
        enableSort={false}
      />
    </Stack>
  );
};

export default UserReport;
