import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  IconButton,
  Toolbar,
  useMediaQuery,
  Button,
  Typography,
} from "@mui/material";

// project import
import AppBarStyled from "./AppBarStyled";

// assets
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useUserInfo } from "store/selectors/auth";
import { useCookies } from "react-cookie";
import { logout } from "store/reducers/auth";
import { useDispatch } from "react-redux";

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }) => {
  const user = useUserInfo();
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  const navigate = useNavigate();

  const iconBackColor = "grey.100";
  const iconBackColorOpen = "grey.200";

  const handleLogout = () => {
    removeCookie("token");
    dispatch(logout());
  };

  // common header
  const mainHeader = (
    <Toolbar>
      <IconButton
        disableRipple
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        color="secondary"
        sx={{
          color: "text.primary",
          bgcolor: open ? iconBackColorOpen : iconBackColor,
          ml: { xs: 0, lg: -2 },
        }}
      >
        {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </IconButton>
      <IconButton
        disableRipple
        aria-label="open drawer"
        onClick={() => navigate(-1)}
        edge="start"
        color="secondary"
        sx={{
          color: "text.primary",
          bgcolor: iconBackColor,
          ml: 1,
        }}
      >
        <ArrowLeftOutlined />
      </IconButton>

      <div style={{ flex: 1 }} />
      <Typography sx={{ px: 3 }}>Logged in as : {user.username}</Typography>

      <Button onClick={handleLogout} variant="outlined">
        Logout
      </Button>
    </Toolbar>
  );

  // app-bar params
  const appBar = {
    position: "fixed",
    color: "inherit",
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

export default Header;
