import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import axios from "axios";

import * as yup from "yup";
import DynamicForm from "components/forms/DynamicForm";
import { PROVINCE_WISE_DISTRICT_OPTIONS } from "constants";
import { API_ENDPOINT } from "constants";
import { PROVINCE_OPTIONS } from "constants";
import React, { useState } from "react";
import { getCookie } from "utils/getCookie";
import { useFormik } from "formik";
import { useAlert } from "AlertContext";
import { useCreateTeacher } from "api/teacher";
import { useQueryClient } from "@tanstack/react-query";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useBooks } from "api/book";

import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useCreateSpecimen } from "api/specimen";

const getToken = () => getCookie("token");

const StyledAutocomplete = styled(Autocomplete)`
  .MuiOutlinedInput-root {
    padding: 0;

    input {
      padding: 10.5px 14px 10.5px 12px !important;
    }
  }
`;

const fields = [
  {
    name: "name",
    type: "text",
    label: "Name",
    itemProps: {
      xs: 12,
      sm: 6,
    },
  },
  {
    name: "phoneNumber",
    type: "text",
    label: "Phone Number",
    itemProps: {
      xs: 12,
      sm: 6,
    },
  },
  {
    name: "subject",
    type: "text",
    label: "Subject",
    itemProps: {
      xs: 12,
      sm: 6,
    },
  },
  {
    name: "level",
    type: "text",
    label: "Level",
    itemProps: {
      xs: 12,
      sm: 6,
    },
  },
  {
    name: "publication",
    type: "text",
    label: "Publication",
    itemProps: {
      xs: 12,
      sm: 12,
    },
  },
  {
    name: "school",
    type: "autocomplete-api",
    label: "School",
    fetchFn: async ({ value }) => {
      const response = await axios
        .get(`${API_ENDPOINT}/schools`, {
          headers: { Authorization: `Bearer: ${getToken()}` },
          params: {
            perPage: 100,
            page: 1,
            search: value,
          },
        })
        .then((res) =>
          res.data.schools.map((school) => ({
            label: school.name,
            value: school.id,
          }))
        );

      return response;
    },
  },
  {
    name: "province",
    type: "select-search",
    label: "Province",
    options: PROVINCE_OPTIONS,
    itemProps: { xs: 12, sm: 4 },
  },
  {
    name: "district",
    type: "text",
    label: "District",
    options: PROVINCE_WISE_DISTRICT_OPTIONS,
    itemProps: { xs: 12, sm: 4 },
  },
  {
    name: "address",
    type: "text",
    label: "Area",
    itemProps: {
      xs: 12,
      sm: 4,
    },
  },
  {
    name: "email",
    type: "text",
    label: "Email",
    itemProps: {
      xs: 12,
      sm: 12,
    },
  },
];

const CreateTeacher = ({ tab }) => {
  const showAlert = useAlert();

  const queryClient = useQueryClient();

  const { data: books } = useBooks();

  const { mutateAsync: mutateCreateTeacher } = useCreateTeacher();

  const { mutateAsync: mutateCreateSpecimen } = useCreateSpecimen();

  const [specimens, setSpecimens] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    const errors = await form.validateForm();

    // Validate specimens here

    specimens.forEach((specimen) => {
      if (!specimen.book || !specimen.specimenGivenDate) {
        errors.specimens = true;
      }
    });

    if (Object.keys(errors).length > 0) {
      showAlert("error", "Please fill all the required fields");
      return;
    }

    const values = form.values;
    try {
      if (!values.school) {
        values.school = null;
      }
      setLoading(true);

      const teacher = await mutateCreateTeacher(values);

      await Promise.all(
        specimens.map(async (specimen) =>
          mutateCreateSpecimen({
            ...specimen,
            teacher: teacher.id,
            book: specimen.book.value,
          })
        )
      );

      showAlert("success", "Teacher Added Successfully");
      form.resetForm({
        values: {
          name: "",
          phoneNumber: "",
          address: "",
          email: "",
          subject: "",
          publication: "",
          level: "",
          province: "",
          district: "",
          school: "",
        },
      });
      setSpecimens([]);
      //

      queryClient.invalidateQueries(["Teachers"]);
    } catch (err) {
      const message = err.response.data.message;
      showAlert("error", message);
    } finally {
      setLoading(false);
    }
  };

  const form = useFormik({
    initialValues: {
      name: "",
      phoneNumber: "",
      address: "",
      email: "",
      subject: "",
      school: "",
      level: "",
      province: "",
      district: "",
      publication: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
      phoneNumber: yup.string().required("Phone number is required"),
    }),
    onSubmit: handleSubmit,
  });

  const handleSpecimenChange = (i, key, value) => {
    const newSpecimens = [...specimens];
    newSpecimens[i][key] = value;
    setSpecimens(newSpecimens);
  };

  if (tab !== "teachers") return null;

  return (
    <div>
      <Typography variant="h4" color="#ee7600" gutterBottom>
        Create Teacher
      </Typography>

      <Box display="flex" gap="20px" alignItems="center" sx={{ mb: 4 }}>
        <DynamicForm
          orientation="column"
          form={form}
          submitLabel={"Create"}
          hideSubmitButton
          fields={fields}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4" color="primary" gutterBottom>
          Teacher Specimens
        </Typography>

        <Button
          color="success"
          variant="contained"
          startIcon={<PlusOutlined />}
          disabled={loading}
          onClick={() =>
            setSpecimens([
              ...specimens,
              { book: "", specimenGivenDate: dayjs(), note: "", level: "" },
            ])
          }
        >
          Add Specimen
        </Button>
      </Box>

      <Box
        sx={{ mb: 2, pt: 2, gap: 2, display: "flex", flexDirection: "column" }}
      >
        {specimens.map((specimen, i) => (
          <Card key={`Specimen - ${i}`}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <StyledAutocomplete
                    fullWidth
                    options={books.map(({ id, name }) => ({ value: id, name }))}
                    getOptionLabel={(option) => option.name || ""}
                    value={specimen.book}
                    onChange={(event, newValue) =>
                      handleSpecimenChange(i, "book", newValue)
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={"Book"} />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Specimen Given Date"
                    sx={{ width: "100%" }}
                    value={specimen.specimenGivenDate}
                    onChange={(newValue) =>
                      handleSpecimenChange(i, "specimenGivenDate", newValue)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    label={"Remarks"}
                    value={specimen.note}
                    onChange={(e) =>
                      handleSpecimenChange(i, "note", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    label={"Level"}
                    value={specimen.level}
                    onChange={(e) =>
                      handleSpecimenChange(i, "level", e.target.value)
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end", p: 3, pt: 0 }}>
              <Button
                color="error"
                variant="contained"
                startIcon={<DeleteOutlined />}
                disabled={loading}
                onClick={() =>
                  setSpecimens(specimens.filter((_, index) => index !== i))
                }
              >
                Delete
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button
          color="orange"
          sx={{ color: "white" }}
          variant="contained"
          startIcon={<PlusOutlined />}
          onClick={handleSubmit}
          disabled={loading}
        >
          Create Teacher
        </Button>
      </Stack>
    </div>
  );
};

export default CreateTeacher;
