import CustomTable from "components/tables/CustomTable";
import React from "react";
import { useUserInfo } from "store/selectors/auth";

const headers = [
  { key: "subject", label: "Subject" },
  { key: "level", label: "Level" },
  {
    key: "name",
    label: "Name",
    type: "link",

    getValue: (row) => row.name,
    getLink: (row) => `/teachers/info/${row.id}`,
  },
  { key: "phoneNumber", label: "Phone Number" },
  { key: "publication", label: "Publication" },
  {
    key: "schoolName",
    label: "School",
    type: "link",
    getValue: (row) => row.school?.name,
    getLink: (row) => `/schools/${row.school?.id}`,
  },
  { key: "address", label: "Area" },
];

const TeachersTable = ({
  data,
  isLoading,
  handleEdit,
  searchQuery,
  handleTableItemClick,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  totalPages,
  disablePagination,
  showActions,
  hideColumns,
}) => {
  const userInfo = useUserInfo();

  return (
    <CustomTable
      data={data}
      headers={headers.filter(({ key }) => !hideColumns?.includes(key))}
      isLoading={isLoading}
      onEdit={handleEdit}
      searchQuery={searchQuery}
      onTableItemClick={handleTableItemClick}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
      disablePagination={disablePagination}
      showActions={
        showActions ?? ["admin", "data-admin"].includes(userInfo?.role)
      }
    />
  );
};

export default TeachersTable;
