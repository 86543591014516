import CustomTable from "components/tables/CustomTable";
import moment from "moment/moment";
import React from "react";
import { useUserInfo } from "store/selectors/auth";

const SpecimenInfoTable = ({ data, showBookName = true }) => {
  const user = useUserInfo();

  const specimenHeaders = [
    showBookName && {
      key: "book",
      label: "Book",
      type: ["admin", "data-admin"].includes(user.role) && "link",
      getLink: (row) => `/books/info/${row.book.id}`,
      getValue: (row) => row.book.name,
    },

    { key: "level", label: "Level" },
    {
      key: "specimenGivenDate",
      label: "Specimen Given Date",
      getValue: (row) =>
        row.specimenGivenDate &&
        moment(row.specimenGivenDate).format("YYYY/MM/DD"),
    },
    {
      key: "hasReceived",
      label: "Has Received?",
      getValue: (row) => (row.hasReceived ? "Yes" : "No"),
    },
    { key: "note", label: "Remarks" },
  ];

  if (data?.[0]?.teacher) {
    specimenHeaders.push({
      key: "teacher",
      label: "Teacher",
      type: "link",
      getLink: (row) => `/teachers/info/${row.teacher?.id}`,
      getValue: (row) => row.teacher?.name,
    });
  }

  if (data?.[0]?.school) {
    specimenHeaders.push({
      key: "school",
      label: "School",
      type: "link",
      getLink: (row) => `/schools/info/${row.school.id}`,
      getValue: (row) => row.school.name,
    });
  }

  return (
    <CustomTable
      headers={specimenHeaders.filter(Boolean)}
      data={data}
      disablePagination
      showActions={false}
    />
  );
};

export default SpecimenInfoTable;
