import { useFormik } from "formik";
import React from "react";
import * as yup from "yup";
import DynamicForm from "./DynamicForm";
import { useCreateSchool, useDeleteSchool, useUpdateSchool } from "api/school";
import { useNavigate } from "react-router";
import ConfirmDialog from "components/dialogs/ConfirmDialog";
import { PROVINCE_OPTIONS } from "constants";
import { PROVINCE_WISE_DISTRICT_OPTIONS } from "constants";
import { useAlert } from "AlertContext";

const fields = [
  {
    name: "name",
    type: "text",
    label: "Name",
    itemProps: { xs: 12 },
  },
  {
    name: "province",
    type: "select-search",
    label: "Province",
    options: PROVINCE_OPTIONS,
    itemProps: { xs: 12, sm: 4 },
  },
  {
    name: "district",
    type: "text",
    label: "District",
    options: PROVINCE_WISE_DISTRICT_OPTIONS,
    itemProps: { xs: 12, sm: 4 },
  },
  {
    name: "address",
    type: "text",
    label: "Area",
    itemProps: { xs: 12, sm: 4 },
  },
  {
    name: "phoneNumber",
    type: "text",
    label: "Phone Number",
    itemProps: { xs: 12, sm: 6 },
  },
  {
    name: "email",
    type: "text",
    label: "Email",
    itemProps: { xs: 12, sm: 6 },
  },
  {
    name: "principalName",
    type: "text",
    label: "Principal Name",
    itemProps: { xs: 12, sm: 6 },
  },
  {
    name: "principalPhoneNumber",
    type: "text",
    label: "Principal Phone Number",
    itemProps: { xs: 12, sm: 6 },
  },
  {
    name: "otherContactPersonName",
    type: "text",
    label: "Contact Person",
    itemProps: { xs: 12, sm: 6 },
  },
  {
    name: "otherContactPersonPhoneNumber",
    type: "text",
    label: "Contact Person Phone Number",
    itemProps: { xs: 12, sm: 6 },
  },
];

const SchoolForm = ({
  initialData,
  disabled,
  navigateToListView = true,
  isNew,
}) => {
  const navigate = useNavigate();
  const { mutateAsync: mutateCreateSchool, isLoading: createSchoolLoading } =
    useCreateSchool();
  const { mutateAsync: mutateUpdateSchool, isLoading: updateSchoolLoading } =
    useUpdateSchool();
  const { mutateAsync: mutateDeleteSchool, isLoading: deleteSchoolLoading } =
    useDeleteSchool();

  const showAlert = useAlert();

  const form = useFormik({
    initialValues: {
      name: initialData?.name ?? "",
      district: initialData?.district ?? "",
      province: initialData?.province ?? "",
      phoneNumber: initialData?.phoneNumber ?? "",
      address: initialData?.address ?? "",
      email: initialData?.email ?? "",
      principalName: initialData?.principalName ?? "",
      principalPhoneNumber: initialData?.principalPhoneNumber ?? "",
      otherContactPersonName: initialData?.otherContactPersonName ?? "",
      otherContactPersonPhoneNumber:
        initialData?.otherContactPersonPhoneNumber ?? "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
      phoneNumber: yup.string().required("Phone number is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        if (isNew) {
          const data = await mutateCreateSchool(values);
          //
          navigateToListView && navigate(`/schools/${data.id}`);
          form.resetForm({
            values: {
              name: "",
              district: "",
              province: "",
              phoneNumber: "",
              address: "",
              email: "",
              principalName: "",
              principalPhoneNumber: "",
              otherContactPersonName: "",
              otherContactPersonPhoneNumber: "",
            },
          });
          showAlert("success", "School Created Successfully");
        } else {
          await mutateUpdateSchool({
            id: initialData.id,
            ...values,
          });
          showAlert("success", "School Updated Successfully");
        }
      } catch (err) {
        const message = err.response.data.message;
        showAlert("error", message);
      }
    },
  });

  const handleDelete = async () => {
    try {
      await mutateDeleteSchool({ id: initialData.id });
      navigate(-1);

      showAlert("success", "School Deleted Successfully");
    } catch (err) {
      const message = err.response.data.message;
      showAlert("error", message);
    }
  };

  return (
    <div>
      <DynamicForm
        form={form}
        submitLabel={isNew ? "Create" : "Update School"}
        fields={fields}
        disabled={disabled || createSchoolLoading || updateSchoolLoading}
        additionalButtons={
          !isNew && (
            <>
              <ConfirmDialog
                onConfirm={handleDelete}
                buttonProps={{
                  variant: "outlined",
                  color: "error",
                  text: "Delete",
                  disabled:
                    disabled ||
                    createSchoolLoading ||
                    updateSchoolLoading ||
                    deleteSchoolLoading ||
                    initialData.teachers.length !== 0,
                }}
              />
            </>
          )
        }
      />
    </div>
  );
};

export default SchoolForm;
